import { useState } from 'react';

type AnswerObj = {
  image: string;
};

function Answer() {
  const [answer, setAnswer] = useState<AnswerObj>();

  const handlerGifChange = () => {
    fetch('https://yesno.wtf/api')
      .then((r) => r.json())
      .then((data) => setAnswer(data));
  };

  return (
    <div>
      {answer ? (
        <img
          style={{ width: '900px', marginBottom: '50px' }}
          src={answer.image}
          alt="гифка не прогрузилась"
        />
      ) : null}
      <div>
        <button className="btn-last" onClick={handlerGifChange}>
          Получить ответ на вопрос
        </button>
      </div>
    </div>
  );
}

export default Answer;
