import React, { useState } from 'react';
import './App.css';
import WordCloud from '../WordCloud/WordCloud';
import Colors from '../Colors/Colors';
import Answer from '../Answer/Answer';

import palette from './color-palette.svg';
import words from './wordcloud.svg';
import magic from './crystal-ball-svgrepo-com.svg';

function App() {
  const [component, setComponent] = useState('');
  const handlerForFirstBtn = () => {
    setComponent('wordcloud');
  };
  const handlerForSecondBtn = () => {
    setComponent('colors');
  };
  const handlerForThirdBtn = () => {
    setComponent('answer');
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="flex-container">
          <div className="mt-2">
            <div>
              <img src={words} className="App-logo" alt="logo" />
            </div>
            <button onClick={handlerForFirstBtn}>Сгенерировать облако</button>
          </div>
          <div className="mt-2">
            <div>
              <img src={palette} className="App-logo" alt="logo" />
            </div>
            <button onClick={handlerForSecondBtn}>Создать палетку</button>
          </div>
          <div className="mt-2">
            <div>
              <img src={magic} className="App-logo" alt="logo" />
            </div>
            <button onClick={handlerForThirdBtn}>Испытать судьбу</button>
          </div>
        </div>
        <div className="flex-container"></div>
        <div style={{ display: 'flex' }}>
          {component === 'wordcloud' ? <WordCloud /> : null}
          {component === 'colors' ? <Colors /> : null}
          {component === 'answer' ? <Answer /> : null}
        </div>
      </header>
    </div>
  );
}

export default App;
