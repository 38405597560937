import { useState } from 'react';

function WordCloud() {
  const string =
    'JAVASCRIPT Fullstack-разработчик Backend express React Elbrus coding bootcamp AGILE Stand-up daily TypeScript HTML CSS DataScience cookies Elbrus Elbrus App Eslint JAVASCRIPT Fullstack-разработчик Backend express React Elbrus coding bootcamp AGILE Stand-up daily TypeScript HTML CSS DataScience cookies Elbrus Elbrus App Eslint JAVASCRIPT Fullstack-разработчик Backend express React Elbrus coding bootcamp AGILE Stand-up daily TypeScript HTML CSS DataScience cookies Elbrus Elbrus App Eslint';

  const [cloud, setCloud] = useState<string[]>([]);

  const [textInput, setInput] = useState('');

  const handlerInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setInput(event.target.value);
  };

  const handlerButtonCloud = () => {
    fetch(
      `https://quickchart.io/wordcloud?text=${
        textInput === '' ? string : textInput
      }`
    )
      .then((res) => res.text())
      .then((data) =>
        setCloud((prev) => {
          return [...prev, data];
        })
      );
  };

  const handlerDeleteCloud = (
    event: React.MouseEvent<HTMLAnchorElement>,
    id: number
  ) => {
    event.preventDefault();
    const newClouds = [...cloud];
    newClouds.splice(id, 1);
    setCloud(newClouds);
  };

  return (
    <div>
      <div className="flex-container">
        {cloud.length > 0 ? (
          cloud.map((el, i) => (
            <div key={i}>
              <div dangerouslySetInnerHTML={{ __html: el }} />
              <a
                href="/"
                style={{ width: '200px' }}
                onClick={(event) => handlerDeleteCloud(event, i)}
              >
                Удалить облако
              </a>
            </div>
          ))
        ) : (
          <div />
        )}
      </div>
      <div className="flex-container-colunm">
        <textarea
          value={textInput}
          onChange={handlerInputChange}
          rows={3}
          placeholder="Введите текст"
        />
        <button className="btn-last" onClick={handlerButtonCloud}>
          Получить облако
        </button>
      </div>
    </div>
  );
}

export default WordCloud;
