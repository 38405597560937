import { useEffect, useState } from 'react';

type Color = {
  color: string;
  pinned?: boolean;
};

function Colors() {
  const [colors, setColors] = useState<Color[]>([]);

  const getRandomColor = (): Promise<any> => {
    // return fetch('http://api.creativehandles.com/getRandomColor').then((res) =>
    //   res.json()
    // );
    return fetch('https://x-colors.yurace.pro/api/random').then((res) =>
      res.json()
    );
  };

  useEffect(() => {
    handlerColorClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerColorClick = () => {
    if (colors.length === 0) {
      for (let i = 0; i < 5; i++) {
        getRandomColor().then((data) => {
          setColors((prev) => {
            return [...prev, { color: data.hex }];
          });
        });
      }
    } else {
      for (let i = 0; i < 5; i++) {
        if (colors[i].pinned === undefined) {
          getRandomColor().then((data) => {
            setColors((prev) => {
              const newColors = [...prev];
              newColors[i] = { color: data.hex };
              return newColors;
            });
          });
        }
      }
    }
  };

  const handlerPinClick = (id: number) => {
    if (colors[Number(id)].pinned === undefined) {
      const newColors = [...colors];
      newColors[Number(id)].pinned = true;
      setColors(newColors);
    } else {
      const newColors = [...colors];
      newColors[Number(id)].pinned = undefined;
      setColors(newColors);
    }
  };

  return (
    <div>
      <div className="all-palette">
        {colors.length === 0
          ? 'Loading...'
          : colors.map((color, i) => (
              <div
                className="palette"
                key={i}
                style={{ backgroundColor: `${color.color}` }}
              >
                {color.color}
                {color.pinned ? (
                  <button
                    onClick={() => handlerPinClick(i)}
                    style={{ width: '100px' }}
                    className="pin-btn"
                  >
                    Unpin
                  </button>
                ) : (
                  <button
                    onClick={() => handlerPinClick(i)}
                    style={{ width: '100px' }}
                    className="pin-btn"
                  >
                    Pin
                  </button>
                )}
              </div>
            ))}
      </div>
      <button
        className="btn-last btn-change-colors"
        onClick={handlerColorClick}
      >
        Поменять цвета
      </button>
    </div>
  );
}

export default Colors;
